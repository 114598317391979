// src/App.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './i18n';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './auth/Login';
import EditorPage from './auth/EditorPage';
import ProtectedRoute from './auth/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import MainContent from './MainContent';

function App() {
  const { t } = useTranslation();

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Ruta para la página de login */}
          <Route path="/login" element={<Login />} />

          {/* Ruta protegida para el editor */}
          <Route
            path="/editor"
            element={
              <ProtectedRoute>
                <EditorPage />
              </ProtectedRoute>
            }
          />

          {/* Ruta para el contenido principal */}
          <Route path="/" element={<MainContent />} />

          {/* /contact -> MainContent con scrollTo="contact" */}
          <Route path="/contact" element={<MainContent scrollTo="contacto" />} />
          <Route path="/mision" element={<MainContent scrollTo="mision-objetivos" />} />
          <Route path="/comp" element={<MainContent scrollTo="comparaciones" />} />
          <Route path="/comparison" element={<MainContent scrollTo="comparaciones" />} />
          <Route path="/testimonials" element={<MainContent scrollTo="testimonios" />} />


          {/* Redirigir cualquier otra ruta a la página principal */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

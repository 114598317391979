// src/EditorPage.js
import React, { useContext, useEffect, useState } from 'react';
import Editor from '@react-page/editor';
import slate from '@react-page/plugins-slate';
import { imagePlugin } from '@react-page/plugins-image';
import '@react-page/editor/lib/index.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const cellPlugins = [slate(), imagePlugin()];

const EditorPage = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [value, setValue] = useState(null);

  useEffect(() => {
    const saved = localStorage.getItem('editorContent');
    if (saved) setValue(JSON.parse(saved));
  }, []);

  const handleSave = () => {
    localStorage.setItem('editorContent', JSON.stringify(value));
    alert('Contenido guardado exitosamente');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Editor Protegido</h1>
      <button type='button' onClick={handleLogout}>Cerrar Sesión</button>
      <Editor
        cellPlugins={cellPlugins}
        value={value}
        onChange={setValue}
      />
      <button type='button' onClick={handleSave}>Guardar Contenido</button>
    </div>
  );
};

export default EditorPage;

// src/MainContent.js
import React, { useEffect, useState } from 'react';
import Editor from '@react-page/editor'; // React Page 5.x
import slate from '@react-page/plugins-slate';
import { imagePlugin } from '@react-page/plugins-image';
import '@react-page/editor/lib/index.css';

import Header from './components/Header';
import Hero from './components/Hero';
import BlurFade from './components/BlurFade';
import DoubleImageComparison from './components/DoubleComparisonContainer';
import Testimonials from './components/Testimonials';
import CTA from './components/CTA';
import WhatsAppButton from './components/WhatsAppButton';
import Footer from './components/Footer';
import MissionObjectives from './components/MissionObjectives';

// Plugins de React Page
const cellPlugins = [slate(), imagePlugin()];

export default function MainContent({ scrollTo }) {
    const [content, setContent] = useState(null);

    // Cargamos el contenido de localStorage (si existe)
    useEffect(() => {
        const saved = localStorage.getItem('editorContent');
        if (saved) {
            setContent(JSON.parse(saved));
        }
    }, []);

    // Efecto para scrollear a la sección según scrollTo
    useEffect(() => {
        if (scrollTo) {
            const targetEl = document.getElementById(scrollTo);
            if (targetEl) {
                // Scroll sin animación
                targetEl.scrollIntoView({ behavior: 'auto' });

                // O con animación:
                // targetEl.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [scrollTo]);

    return (
        <div>
            {content ? (
                <Editor
                    readOnly
                    cellPlugins={cellPlugins}
                    value={content}
                />
            ) : (
                <p>No hay contenido para mostrar. Ve al editor para agregar contenido.</p>
            )}

            <Header />
            <Hero />
            <MissionObjectives />

            <BlurFade>

                {/* Supongamos que la sección de contacto es algo como esto: */}
            </BlurFade>
            <DoubleImageComparison />
            <Testimonials />
            <CTA />
            <WhatsAppButton
                phoneNumber={process.env.REACT_APP_MAIN_TLF_NO_SPACE}
                message={'Mensaje predefinido'}
            />
            <Footer />
        </div>
    );
}
